import styled, { css } from 'styled-components';

import { atMinWidth } from '../../../styles/atoms/breakpoints';
import { colors } from '../../../styles/atoms/colors';
import { font } from '../../../styles/atoms/typography';

export const StickyContainer = styled.div`
  display: contents;

  ${atMinWidth.lg`
    display: block;
    height: ${props =>
      props.index === 4 ? 'fit-content' : props.index === 3 ? '65vh' : '100vh'};
  `}
`;

export const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 27px;
  max-width: 570px;
  transition: 0.7s;

  ${props =>
    props.index === 4 &&
    css`
      position: absolute;
      top: unset;
      bottom: -40vh;
      height: 45vh;
    `}
`;

export const Kicker = styled.p`
  ${font('overline', 'md', '700')}
  color: ${colors.base.white};
`;

export const Heading = styled.h2`
  ${font('display', 'md', '700')}
  color: ${colors.base.white};

  ${atMinWidth.sm`
    ${font('display', 'lg', '700')}
  `}

  ${atMinWidth.md`
    ${font('display', 'xl', '700')}
  `}
`;

export const Body = styled.p`
  ${font('text', 'md', '400')}
  color: ${colors.base.white};
`;
